.title {
  font-size: 20px;
  font-weight: 600;
  color: #111456d9;
}

@media (min-width: 480px) {
  .title {
    font-size: 22px;
  }
}

@media (min-width: 1024px) {
  .title {
    font-size: 28px;
  }
}

.background_light {
  background-color: white;
}
